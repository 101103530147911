import { Flex, Heading } from '@conteg/ui';
import { Text } from 'components/styled';
import { useTranslation } from 'react-i18next';

const ErrorFallback = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="4rem" marginTop="6rem">
      <Heading variant="h2" title={t('ErrorBoundary.Heading')} />
      <Text variant="large" content={t('ErrorBoundary.Info')} />
    </Flex>
  );
};

export default ErrorFallback;
