import { Heading, styled } from '@conteg/ui';
import { Text } from 'components/styled';
import { breakpoints } from 'utils/styles/breakpoints';

type LayoutHeadingsProps = {
  heading: string;
  subHeading: string;
  displayShadow: boolean;
};

type WrapperProps = {
  addNegativeMargin: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  max-width: 100%;
  position: relative;
  text-align: center;
  ${(props) => props.addNegativeMargin && 'margin-bottom: -5%;'}
  h1,
  p {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Subheading = styled(Text)`
  margin-top: 4rem;

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    margin-top: 0;
  }
`;

const Shadow = styled.div`
  position: absolute;
  width: 92rem;
  height: 27.5rem;
  left: 50%;
  top: 65%;

  background: ${(props) => props.theme.colors.darkBackground};
  filter: blur(9.5rem);
  transform: translateX(-50%) matrix(-1, 0, 0, 1, 0, 0);
  z-index: -1;
`;

const LayoutHeadings = ({
  heading,
  subHeading,
  displayShadow,
}: LayoutHeadingsProps) => {
  return (
    <Wrapper addNegativeMargin={displayShadow}>
      <Heading variant="h1" title={heading} />
      <Subheading variant="normal" content={subHeading} />
      {displayShadow ? <Shadow /> : null}
    </Wrapper>
  );
};

export default LayoutHeadings;
