import { styled } from '@conteg/ui';
import { zIndexes } from 'components/public-layout/z-indexes';
import { breakpoints } from 'utils/styles/breakpoints';

type EllipsesProps = {
  width: string;
};

export const EllipsesSize = {
  small: '121rem',
  medium: '132rem',
  big: '166.5rem',
};

const EllipsesWrapper = styled.div`
  position: relative;
  z-index: ${zIndexes.ellipses};

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    position: absolute;
    width: ${EllipsesSize.medium};
    top: 55%;
    svg {
      width: ${EllipsesSize.medium};
    }
  }
`;

const Ellipses = ({ width = EllipsesSize.big }: EllipsesProps) => {
  return (
    <EllipsesWrapper>
      <svg
        width={width}
        viewBox="0 0 668 158"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.7"
          d="M547.492 54.5065C547.492 56.4928 546.09 58.4896 543.275 60.4674C540.468 62.439 536.312 64.3509 530.936 66.1778C520.187 69.8305 504.619 73.1239 485.366 75.8912C446.864 81.4252 393.665 84.849 334.897 84.849C276.13 84.849 222.931 81.4252 184.428 75.8912C165.175 73.1239 149.608 69.8305 138.859 66.1778C133.483 64.3509 129.327 62.439 126.52 60.4674C123.704 58.4896 122.302 56.4928 122.302 54.5065C122.302 52.5202 123.704 50.5234 126.52 48.5457C129.327 46.5741 133.483 44.6622 138.859 42.8353C149.608 39.1826 165.175 35.8891 184.428 33.1218C222.931 27.5879 276.13 24.1641 334.897 24.1641C393.665 24.1641 446.864 27.5879 485.366 33.1218C504.619 35.8891 520.187 39.1826 530.936 42.8353C536.312 44.6622 540.468 46.5741 543.275 48.5457C546.09 50.5234 547.492 52.5202 547.492 54.5065Z"
          stroke="url(#paint0_linear_57_2)"
          strokeWidth="0.5"
        />
        <path
          opacity="0.6"
          d="M598.262 60.364C598.262 63.4039 596.476 66.4115 592.982 69.3554C589.49 72.2974 584.325 75.1468 577.65 77.8678C564.302 83.3089 544.972 88.2142 521.068 92.3357C473.263 100.578 407.211 105.677 334.245 105.677C261.28 105.677 195.228 100.578 147.423 92.3357C123.518 88.2142 104.188 83.3089 90.8403 77.8678C84.1651 75.1468 79.0003 72.2974 75.5086 69.3554C72.0146 66.4115 70.2285 63.4039 70.2285 60.364C70.2285 57.3241 72.0146 54.3165 75.5086 51.3726C79.0003 48.4306 84.1651 45.5813 90.8403 42.8603C104.188 37.4191 123.518 32.5138 147.423 28.3923C195.228 20.1501 261.28 15.0508 334.245 15.0508C407.211 15.0508 473.263 20.1501 521.068 28.3923C544.972 32.5138 564.302 37.4191 577.65 42.8603C584.325 45.5813 589.49 48.4306 592.982 51.3726C596.476 54.3165 598.262 57.3241 598.262 60.364Z"
          stroke="url(#paint1_linear_57_2)"
          strokeWidth="0.5"
        />
        <path
          opacity="0.5"
          d="M634.715 70.1279C634.715 74.4919 632.652 78.7765 628.675 82.942C624.696 87.1086 618.815 91.1398 611.22 94.9876C596.031 102.683 574.035 109.62 546.835 115.449C492.437 127.106 417.276 134.317 334.248 134.317C251.219 134.317 176.058 127.106 121.661 115.449C94.46 109.62 72.4644 102.683 57.2751 94.9876C49.6801 91.1398 43.7994 87.1086 39.8206 82.942C35.8428 78.7765 33.7803 74.4919 33.7803 70.1279C33.7803 65.7639 35.8428 61.4793 39.8206 57.3138C43.7994 53.1472 49.6801 49.116 57.2751 45.2682C72.4644 37.5728 94.46 30.6355 121.661 24.8068C176.058 13.1502 251.219 5.93848 334.248 5.93848C417.276 5.93848 492.437 13.1502 546.835 24.8068C574.035 30.6355 596.031 37.5728 611.22 45.2682C618.815 49.116 624.696 53.1472 628.675 57.3138C632.652 61.4793 634.715 65.7639 634.715 70.1279Z"
          stroke="url(#paint2_linear_57_2)"
          strokeWidth="0.5"
        />
        <path
          opacity="0.4"
          d="M667.258 79.2407C667.258 89.9652 658.053 100.261 641.197 109.679C624.362 119.086 599.985 127.565 569.841 134.689C509.556 148.936 426.26 157.75 334.246 157.75C242.231 157.75 158.936 148.936 98.6512 134.689C68.5068 127.565 44.1297 119.086 27.2948 109.679C10.4388 100.261 1.2334 89.9652 1.2334 79.2407C1.2334 68.5163 10.4388 58.2204 27.2948 48.8023C44.1297 39.3959 68.5068 30.9168 98.6512 23.7928C158.936 9.54581 242.231 0.731445 334.246 0.731445C426.26 0.731445 509.556 9.54581 569.841 23.7928C599.985 30.9168 624.362 39.3959 641.197 48.8023C658.053 58.2204 667.258 68.5163 667.258 79.2407Z"
          stroke="url(#paint3_linear_57_2)"
          strokeWidth="0.5"
        />
        <defs>
          <linearGradient
            id="paint0_linear_57_2"
            x1="375.273"
            y1="-15.1969"
            x2="382.99"
            y2="29.5737"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00B2FF" stopOpacity="0.75" />
            <stop offset="0.401042" stopColor="#00D1FF" stopOpacity="0.85" />
            <stop offset="1" stopColor="#75E3FB" stopOpacity="0.63" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_57_2"
            x1="384.375"
            y1="-43.4496"
            x2="397.989"
            y2="22.3962"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00B2FF" stopOpacity="0.75" />
            <stop offset="0.401042" stopColor="#00D1FF" stopOpacity="0.85" />
            <stop offset="1" stopColor="#75E3FB" stopOpacity="0.63" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_57_2"
            x1="391.292"
            y1="-76.6941"
            x2="414.7"
            y2="14.3965"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00B2FF" stopOpacity="0.75" />
            <stop offset="0.401042" stopColor="#00D1FF" stopOpacity="0.85" />
            <stop offset="1" stopColor="#75E3FB" stopOpacity="0.63" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_57_2"
            x1="397.464"
            y1="-100.208"
            x2="428.599"
            y2="9.65254"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00B2FF" stopOpacity="0.75" />
            <stop offset="0.401042" stopColor="#00D1FF" stopOpacity="0.85" />
            <stop offset="1" stopColor="#75E3FB" stopOpacity="0.63" />
          </linearGradient>
        </defs>
      </svg>
    </EllipsesWrapper>
  );
};

export default Ellipses;
