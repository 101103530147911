import { useAuth } from '@conteg/auth';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appRoutes } from 'utils/routing/routes';

const protectedRoutes = Object.entries(appRoutes)
  .filter(([, route]) => route.protected)
  .map(([, route]) => route.path);

export const useStoredPath = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    const storedPath = window.localStorage.getItem('stored-path');

    if (!isAuthenticated && protectedRoutes.includes(location.pathname))
      window.localStorage.setItem('stored-path', currentPath);

    if (isAuthenticated && storedPath === currentPath)
      window.localStorage.removeItem('stored-path');

    if (isAuthenticated && storedPath) navigate(storedPath);
  }, [isAuthenticated, location, navigate]);
};
