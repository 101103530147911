import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import cs from './cs.json';

// Fix based on https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  lng: 'cs',
  fallbackLng: 'cs',
  debug: false,
  returnNull: false,
  resources: {
    cs: {
      translation: cs,
    },
  },
});

export default i18n;
