import { styled } from '@conteg/ui';
import { zIndexes } from 'components/public-layout/z-indexes';

type ShadowProps = {
  w: string;
  left: string;
};

const Shadow = styled.div<ShadowProps>`
  position: absolute;
  width: ${(props) => props.w};
  height: 1.5rem;

  top: 53.25rem;
  left: ${(props) => props.left};
  z-index: ${zIndexes.image.shadow};

  background: ${(props) => props.theme.colors.black};
  filter: blur(1.25rem);
`;

export const Shadows = () => {
  return (
    <>
      <Shadow w="58.5rem" left="-2rem" />
      <Shadow w="8rem" left="21.25rem" />
      <Shadow w="3.5rem" left="3.5rem" />
      <Shadow w="3.5rem" left="50.75rem" />
    </>
  );
};
