import { Flex } from '@conteg/ui';
import LayoutWrapper from 'components/public-layout/layout-wrapper';
import { ReactNode } from 'react';
import { usePublicLayoutSettings } from 'utils/hooks/use-public-layout-settings';

const PublicRoutesLayout = ({ children }: { children: ReactNode }) => {
  const layoutSettings = usePublicLayoutSettings();

  return (
    <LayoutWrapper {...layoutSettings}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        width="100%"
        height="100%"
      >
        {children}
      </Flex>
    </LayoutWrapper>
  );
};

export default PublicRoutesLayout;
