import { authLinks, AuthRoutes } from '@conteg/auth';

const changePasswordAuthLink = authLinks.find(
  (link) => link.route === AuthRoutes.CHANGE_PASSWORD
)?.link as string;

const myProfiledAuthLink = authLinks.find(
  (link) => link.route === AuthRoutes.MY_PROFILE
)?.link as string;

export const appRoutes = {
  notFound: {
    path: '*',
    protected: false,
  },
  home: {
    path: '/',
    protected: false,
  },
  logIn: {
    path: '/login',
    protected: false,
  },
  logOut: {
    path: '/logout',
    protected: false,
  },
  loggedOut: {
    path: '/logged-out',
    protected: false,
  },
  register: {
    path: '/register',
    protected: false,
  },
  registerByEmailInvitation: {
    path: '/register-by-email-invitation/:token/:userEmail',
    protected: false,
  },
  forgottenPassword: {
    path: '/forgotten-password',
    protected: false,
  },
  changeForgottenPassword: {
    path: '/forgotten-password/:token/:expirationTime',
    protected: false,
  },
  error: {
    path: '/error',
    protected: false,
  },
  dashboard: {
    path: '/dashboard',
    protected: true,
  },
  changePassword: {
    path: changePasswordAuthLink,
    protected: true,
  },
  profile: {
    path: myProfiledAuthLink,
    protected: true,
  },
  deviceCode: {
    path: '/device',
    protected: true,
  },
};
