import { styled } from '@conteg/ui';
import box from 'assets/box.png';
import { Background } from 'components/public-layout/image/background';
import { Shadows } from 'components/public-layout/image/shadows';
import { zIndexes } from 'components/public-layout/z-indexes';
import { useViewportSize } from 'utils/hooks/use-viewport-size';
import { breakpoints } from 'utils/styles/breakpoints';

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  width: 54rem;
  height: 55.75rem;
  margin-top: 15.75rem;
  margin-bottom: -11.25rem;
`;

const StyledImage = styled.div`
  position: absolute;
  width: 54rem;
  height: 55.75rem;
  z-index: ${zIndexes.image.image};

  background: linear-gradient(
      89.57deg,
      rgba(0, 7, 10, 0.07) 40.52%,
      rgba(1, 8, 10, 0.1) 58.83%
    ),
    url(${box});
  mix-blend-mode: normal;
  opacity: 0.98;
  transform: matrix(-1, 0, 0, 1, 0, 0), scale(-1);
`;

const Outline = styled.div`
  width: 53rem;
  height: 51rem;

  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndexes.image.outline};

  border: 0.5rem solid;

  border-image: linear-gradient(
      129.82deg,
      rgba(0, 240, 255, 0.833333) 3.37%,
      rgba(0, 209, 255, 0.833333) 8.9%,
      rgba(117, 251, 243, 0) 37.63%
    )
    1;
`;

const Image = () => {
  const { width } = useViewportSize();

  if (width < breakpoints.smallDevice.width) return null;

  return (
    <Wrapper>
      <Outline />
      <StyledImage />
      <Shadows />
      <Background />
    </Wrapper>
  );
};

export default Image;
