import { authLinks, AuthRoutes, useAuth, useUserData } from '@conteg/auth';
import { ConfirmModal, Navbar, styled } from '@conteg/ui';
import { TwoColumnContent } from 'components/styled';
import {
  COMPANY_DETAIL_EXTERNAL_ROUTE,
  INSTALLATIONS_LIST_ROUTE,
} from 'config';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useViewportSize } from 'utils/hooks/use-viewport-size';
import Breadcrumbs from 'utils/layout/breadcrumbs';
import { openExternalLink } from 'utils/open-external-link/open-external-link';
import { appRoutes } from 'utils/routing/routes';
import { breakpoints } from 'utils/styles/breakpoints';
import { substituteRouteParams } from 'utils/substitute-route-params/substitute-route-params';

const Footer = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.typography.text.small.fontSize};
  line-height: ${({ theme }) => theme.typography.text.small.lineHeight};
  font-weight: ${({ theme }) => theme.typography.text.small.fontWeight};
  color: ${({ theme }) => theme.colors.secondary.grey300};
  margin-top: 10rem;
`;

const ProtectedRoutesLayout = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { signoutRedirect } = useAuth();

  const { width } = useViewportSize();
  const isMobile: boolean = width <= breakpoints.smallDevice.width;

  const userData = useUserData();

  const [confirmLogout, setConfirmLogout] = useState<boolean>(false);

  const titles: Record<AuthRoutes, string> = {
    [AuthRoutes.CHANGE_PASSWORD]: t('Navbar.ChangePassword'),
    [AuthRoutes.MY_PROFILE]: t('Navbar.MyProfile'),
  };
  const authMenuLinks = authLinks.map((link) => ({
    label: titles[link.route],
    onClick: () => navigate(link.link),
  }));

  return (
    <>
      <Navbar
        testId="navbar"
        logoLink={{
          label: t('Navbar.Logo'),
          onClick: () => navigate(appRoutes.dashboard.path),
        }}
        navLinks={[
          {
            label: t('NavBar.CompanyDetail'),
            onClick: () => {
              if (userData?.subjectId) {
                //TODO: take from @common/ui when it is ready
                openExternalLink(
                  substituteRouteParams(COMPANY_DETAIL_EXTERNAL_ROUTE, {
                    id: userData.subjectId,
                  })
                );
              }
            },
          },
          {
            label: t('NavBar.InstallationsList'),
            onClick: () => openExternalLink(INSTALLATIONS_LIST_ROUTE),
          },
        ]}
        menuLinks={[
          ...authMenuLinks,
          //Currently we dont have use case for it
          // {
          //   label: t('Navbar.AccessRights'),
          //   onClick: () => navigate(appRoutes.dashboard.path),
          //   icon: { name: 'grants' },
          // },
          {
            label: t('Navbar.Logout'),
            onClick: () => setConfirmLogout(true),
            icon: { name: 'logout' },
          },
        ]}
        user={{ email: userData?.email || '', name: userData?.fullName || '' }}
        lastLinkSeparated={true}
      />
      <TwoColumnContent>
        {!isMobile ? <Breadcrumbs /> : null}
        {children}
      </TwoColumnContent>

      {!isMobile ? (
        <Footer>{t('Footer.Text', { year: new Date().getFullYear() })}</Footer>
      ) : null}

      <ConfirmModal
        testId="confirm-logout"
        isOpen={confirmLogout}
        onClose={() => setConfirmLogout(false)}
        onConfirm={() => signoutRedirect()}
        confirm={{
          text: t('LogOutModal.Confirm'),
          variant: 'primary',
        }}
        cancelText={t('LogOutModal.Cancel')}
        modalTitle={t('LogOutModal.Title')}
        modalDescription={t('LogOutModal.Description')}
      />
    </>
  );
};

export default ProtectedRoutesLayout;
