import { styled } from '@conteg/ui';
import { zIndexes } from 'components/public-layout/z-indexes';
import { breakpoints } from 'utils/styles/breakpoints';

const IdentityCard = styled.div`
  padding: 5rem;
  border-radius: 4rem;
  background: ${({ theme }) => theme.colors.white};
  width: 129rem;
  z-index: ${zIndexes.identityCard};

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    width: 100%;
    padding: 0;
    overflow-y: scroll;
  }
`;

export default IdentityCard;
