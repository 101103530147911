import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { appRoutes } from 'utils/routing/routes';

type ProtectedRouteProps = {
  canAccessThisRoute: boolean;
  children: ReactNode;
  navigateTo?: string;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  canAccessThisRoute,
  navigateTo = appRoutes.home.path,
}) => {
  if (canAccessThisRoute) return <>{children}</>;
  return <Navigate to={navigateTo} />;
};
