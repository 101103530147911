import { useAuth } from '@conteg/auth';
import { Outlet } from 'react-router-dom';
import ProtectedRoutesLayout from 'utils/layout/protected-routes-layout';
import PublicRoutesLayout from 'utils/layout/public-routes-layout';

const Layout: React.FC = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return (
      <ProtectedRoutesLayout>
        <Outlet />
      </ProtectedRoutesLayout>
    );
  }

  return (
    <PublicRoutesLayout>
      <Outlet />
    </PublicRoutesLayout>
  );
};

export default Layout;
