export const zIndexes = {
  ellipses: 750,
  image: {
    backgroundGlow: 751,
    shadow: 752,
    image: 753,
    outline: 754,
  },
  identityCard: 756,
};
