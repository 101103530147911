import { styled } from '@conteg/ui';
import { zIndexes } from 'components/public-layout/z-indexes';
import { breakpoints } from 'utils/styles/breakpoints';

const BackgroundGlowTop = styled.div`
  position: absolute;
  width: 62.5rem;
  height: 22rem;

  top: -6.25rem;
  left: 7.5rem;
  z-index: ${zIndexes.image.backgroundGlow};

  background: ${(props) => props.theme.colors.primary.blue400};
  opacity: 0.3;
  filter: blur(32rem);
  transform: rotate(27.34deg);
`;

const BackgroundGlowMiddle = styled.div`
  position: absolute;
  width: 46rem;
  height: 46.5rem;

  top: 0;
  left: 1.25rem;
  z-index: ${zIndexes.image.backgroundGlow};

  background: ${(props) => props.theme.colors.primary.blue400};
  opacity: 0.2;
  filter: blur(37.5rem);
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;

const BackgroundGlowBottom = styled.div`
  position: absolute;
  width: 30rem;
  height: 39.25rem;

  top: 6.5rem;
  left: 5.75rem;

  background: ${(props) => props.theme.colors.primary.blue400};
  filter: blur(23.75rem);
  transform: rotate(90deg);
`;

const DarkGradient = styled.div`
  position: absolute;
  width: 154rem;
  height: 54rem;
  top: 78%;
  left: 50%;
  transform: translateX(-50%);

  background: linear-gradient(
    180deg,
    rgba(3, 2, 24, 0) 0%,
    ${(props) => props.theme.colors.darkBackground} 100%
  );

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    width: 100vh;
    left: 0;
    top: 25%;
  }
`;

export const Background = () => {
  return (
    <>
      <BackgroundGlowTop />
      <BackgroundGlowMiddle />
      <BackgroundGlowBottom />
      <DarkGradient />
    </>
  );
};
