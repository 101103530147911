import { useStoredPath } from 'utils/hooks/use-stored-path';
import Router from 'utils/routing/router';

const App = () => {
  useStoredPath();

  return <Router />;
};

export default App;
