import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { appRoutes } from 'utils/routing/routes';

type PublicLayoutSettings = {
  headingText: string;
  subheadingText: string;
  isLoginScreen: boolean;
  flexLastChildBottom?: boolean;
  flexFillScreenOnMobile?: boolean;
};

export const usePublicLayoutSettings = (): PublicLayoutSettings => {
  const { t } = useTranslation();
  const location = useLocation();

  switch (location.pathname) {
    case appRoutes.logOut.path:
      return {
        headingText: t('Layout.LogOut.Heading'),
        subheadingText: '',
        isLoginScreen: false,
      };
    case appRoutes.loggedOut.path:
      return {
        headingText: t('Layout.LogOut.Heading'),
        subheadingText: '',
        isLoginScreen: false,
      };
    case appRoutes.register.path:
      return {
        headingText: t('Layout.Register.Heading'),
        subheadingText: t('Layout.Register.SubHeading'),
        isLoginScreen: false,
        flexLastChildBottom: true,
        flexFillScreenOnMobile: true,
      };
    case appRoutes.forgottenPassword.path:
      return {
        headingText: t('Layout.PassRecovery.Heading'),
        subheadingText: t('Layout.PassRecovery.SubHeading'),
        isLoginScreen: false,
        flexLastChildBottom: true,
        flexFillScreenOnMobile: true,
      };
    case appRoutes.error.path:
      return {
        headingText: t('Layout.Error.Heading'),
        subheadingText: t('Layout.Error.Subheading'),
        isLoginScreen: false,
      };
    default:
      return {
        headingText: t('Layout.Heading'),
        subheadingText: t('Layout.Subheading'),
        isLoginScreen: true,
        flexLastChildBottom: true,
      };
  }
};
