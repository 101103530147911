import { theme } from '@conteg/ui';
import { createGlobalStyle } from 'styled-components';

// added ts-ignore because type-check fails on theme.color
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const lightBackground = theme.colors.lightBackground;

export const GlobalStyles = createGlobalStyle`
    body {
        background-color: ${lightBackground};
    }
`;
