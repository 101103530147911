import Ellipses, { EllipsesSize } from 'components/public-layout/ellipses';
import IdentityCard from 'components/public-layout/identity-card';
import Image from 'components/public-layout/image/image';
import LayoutBackground from 'components/public-layout/layout-background';
import LayoutHeadings from 'components/public-layout/layout-headings';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { useViewportSize } from 'utils/hooks/use-viewport-size';
import { breakpoints } from 'utils/styles/breakpoints';

type LayoutWrapperProps = {
  headingText: string;
  subheadingText: string;
  isLoginScreen: boolean;
  children: ReactNode;
};

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 341.5rem;
  height: 192rem;
  max-width: 100vw;
  max-height: 100vh;
  padding: 4rem;

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    height: 100vh;
  }
`;

const LayoutGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 129rem;
  justify-items: center;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    grid-template-columns: 1fr;
  }
`;

const HeadingsAndGraphicsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LayoutWrapper = ({
  headingText,
  subheadingText,
  isLoginScreen,
  children,
}: LayoutWrapperProps) => {
  const { width } = useViewportSize();

  return (
    <LayoutBackground>
      <Wrapper>
        <LayoutGrid>
          <HeadingsAndGraphicsWrapper>
            <LayoutHeadings
              heading={headingText}
              subHeading={subheadingText}
              displayShadow={
                !isLoginScreen || width < breakpoints.smallDevice.width
              }
            />
            {isLoginScreen ? <Image /> : null}
            <Ellipses width={EllipsesSize.small} />
          </HeadingsAndGraphicsWrapper>
          <IdentityCard>{children}</IdentityCard>
        </LayoutGrid>
      </Wrapper>
    </LayoutBackground>
  );
};

export default LayoutWrapper;
