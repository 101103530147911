import {
  Button as ContegUiButton,
  Input as ContegUiInput,
  styled,
  Text as ContegUiText,
} from '@conteg/ui';
import { breakpoints } from 'utils/styles/breakpoints';

const navbarHeight = '18rem';
const marginTop = '10rem';

// TODO: create 2 columns in next version (avatar change, data download)
// https://www.figma.com/file/DCimJjvR5aJfFuEl21ILQV/X2---Admin-prost%C5%99ed%C3%AD-(Client)?node-id=951-12890&t=6uzcq687PN0mZa4i-4
export const TwoColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 201.5rem;

  margin: ${marginTop} auto 0 auto;
  padding: 1rem;

  > div {
    width: 100%;
  }

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    min-height: calc(100vh - ${marginTop} - ${navbarHeight});

    div:last-child {
      flex: 1;
    }

    h3 {
      display: none;
    }
  }
`;

export const PageHeading = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 8rem;
  margin-bottom: 2rem;

  > div {
    width: auto;
  }

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    margin: 0;

    h1 {
      display: none;
    }
  }
`;

export const Input = styled(ContegUiInput)`
  /* TODO: pixels are needed here as the rem on iPhone is different than on web */
  width: 304px;
  height: 56px;
  min-width: 100px;

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    width: 100%;
  }
`;

export const Button = styled(ContegUiButton)`
  /* TODO: pixels are needed here as the rem on iPhone is different than on web */
  width: 304px;
  height: 48px;

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    width: 90%;
  }
`;

export const Text = styled(ContegUiText)`
  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    line-height: normal;
  }
`;
