import 'utils/localization/i18n';

import { ThemeProvider } from '@conteg/ui';
import App from 'app';
import ErrorFallback from 'components/error-fallback/error-fallback';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from 'utils/auth/auth-provider';
import QueryClientProvider from 'utils/gql/query-client-provider';
import { GlobalStyles } from 'utils/styles/global-styles';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider shouldLoadFontFiles={false}>
      <QueryClientProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <BrowserRouter>
            <AuthProvider>
              <GlobalStyles />
              <App />
            </AuthProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);
