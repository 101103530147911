import { styled } from '@conteg/ui';
import { ReactNode } from 'react';
import { useViewportSize } from 'utils/hooks/use-viewport-size';
import { breakpoints } from 'utils/styles/breakpoints';

const Background = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.darkBackground};
  overflow: hidden;
`;

const EllipseGradient = styled.div`
  position: absolute;
  width: 150rem;
  height: 193.5rem;

  left: 55%;
  top: -46%;

  background: ${(props) => props.theme.colors.primary.blue400};
  opacity: 0.6;
  filter: blur(48rem);
  transform: rotate(42.3deg);

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    left: 16rem;
    top: 75%;
    transform: rotate(132.3deg);
  }
`;

const CircleGradient = styled.div`
  position: absolute;
  width: 41rem;
  height: 41rem;
  top: 33%;
  left: 22%;

  background: ${(props) => props.theme.colors.primary.blue400};
  opacity: 0.5;
  filter: blur(25rem);

  @media only screen and (max-width: ${breakpoints.smallDevice.withUnits}) {
    left: -7.5rem;
    top: -16.5rem;
  }
`;

const EllipseBlack = styled.div`
  position: absolute;
  width: 98.5rem;
  height: 24rem;

  top: 15%;

  background: ${({ theme }) => theme.colors.darkBackground};
  filter: blur(8rem);
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;

const DarkGradient = styled.div`
  position: absolute;
  width: 100vw;
  height: 33rem;
  top: 25%;

  background: linear-gradient(
    180deg,
    rgba(3, 2, 24, 0) 0%,
    ${(props) => props.theme.colors.darkBackground} 100%
  );
`;

const LayoutBackground = ({ children }: { children: ReactNode }) => {
  const { width } = useViewportSize();
  return (
    <Background>
      <EllipseGradient />
      <CircleGradient />
      {width < breakpoints.smallDevice.width ? (
        <>
          <EllipseBlack />
          <DarkGradient />
        </>
      ) : null}
      {children}
    </Background>
  );
};

export default LayoutBackground;
