import { useAuth } from '@conteg/auth';
import { Loading } from '@conteg/ui';
import { lazy, Suspense } from 'react';

const ChangeForgottenPassword = lazy(
  () => import('pages/change-forgotten-password/change-forgotten-password')
);
const ChangePassword = lazy(
  () => import('pages/change-password/change-password')
);
const Dashboard = lazy(() => import('pages/dashboard/dashboard'));
const DeviceCode = lazy(() => import('pages/device-code/device-code'));
const Error = lazy(() => import('pages/error/error'));
const ForgottenPasswordPage = lazy(
  () => import('pages/forgotten-password/forgotten-password')
);
const Homepage = lazy(() => import('pages/homepage/homepage'));
const LoggedOut = lazy(() => import('pages/logged-out/logged-out'));
const Login = lazy(() => import('pages/login/login'));
const Logout = lazy(() => import('pages/logout/logout'));
const NotFound = lazy(() => import('pages/not-found/not-found'));
const Profile = lazy(() => import('pages/profile/profile'));

const RegisterByEmailInvitation = lazy(
  () =>
    import('pages/register-by-email-invitation/register-by-email-invitation')
);

import { Route, RouteProps, Routes } from 'react-router-dom';
import Layout from 'utils/layout/layout';
import { ProtectedRoute } from 'utils/routing/protected-route';
import { appRoutes } from 'utils/routing/routes';

type Route = RouteProps & {
  protected?: boolean;
  navigateTo?: string;
};

const routes: Route[] = [
  { ...appRoutes.notFound, element: <NotFound /> },
  { ...appRoutes.home, element: <Homepage /> },
  { ...appRoutes.logIn, element: <Login /> },
  { ...appRoutes.logOut, element: <Logout /> },
  { ...appRoutes.error, element: <Error /> },
  {
    ...appRoutes.registerByEmailInvitation,
    navigateTo: appRoutes.registerByEmailInvitation.path,
    element: <RegisterByEmailInvitation />,
  },
  {
    ...appRoutes.forgottenPassword,
    navigateTo: appRoutes.dashboard.path,
    element: <ForgottenPasswordPage />,
  },
  {
    ...appRoutes.loggedOut,
    navigateTo: appRoutes.dashboard.path,
    element: <LoggedOut />,
  },
  {
    ...appRoutes.dashboard,
    element: <Dashboard />,
  },
  {
    ...appRoutes.changePassword,
    element: <ChangePassword />,
  },
  {
    ...appRoutes.profile,
    element: <Profile />,
  },
  {
    ...appRoutes.deviceCode,
    element: <DeviceCode />,
  },
  {
    ...appRoutes.changeForgottenPassword,
    element: <ChangeForgottenPassword />,
  },
];

const Router = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route element={<Layout />}>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              route.protected ? (
                <ProtectedRoute canAccessThisRoute={isAuthenticated}>
                  <Suspense fallback={<Loading />}>{route.element}</Suspense>
                </ProtectedRoute>
              ) : (
                <ProtectedRoute
                  canAccessThisRoute={
                    route.navigateTo ? !isAuthenticated : true // forbid logged user to access some public routes
                  }
                  navigateTo={route.navigateTo}
                >
                  <Suspense fallback={<Loading />}>{route.element}</Suspense>
                </ProtectedRoute>
              )
            }
          />
        ))}
      </Route>
    </Routes>
  );
};

export default Router;
