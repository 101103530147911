import { Breadcrumb, Breadcrumbs as ContegBreadcrumbs } from '@conteg/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { appRoutes } from 'utils/routing/routes';

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  useEffect(() => {
    const dashboard: Breadcrumb = {
      label: t('Breadcrumbs.Dashboard'),
      url: appRoutes.dashboard.path,
    };

    switch (location.pathname) {
      case appRoutes.changePassword.path:
        setBreadcrumbs([
          dashboard,
          {
            label: t('Breadcrumbs.ChangePassword'),
            url: appRoutes.changePassword.path,
          },
        ]);
        break;
      case appRoutes.deviceCode.path:
        setBreadcrumbs([
          dashboard,
          {
            label: t('Breadcrumbs.DeviceCode'),
            url: appRoutes.deviceCode.path,
          },
        ]);
        break;
      case appRoutes.profile.path:
        setBreadcrumbs([
          dashboard,
          {
            label: t('Breadcrumbs.Profile'),
            url: appRoutes.profile.path,
          },
        ]);
        break;
      default:
        setBreadcrumbs([dashboard]);
        break;
    }
  }, [location, t]);

  return (
    <ContegBreadcrumbs
      items={breadcrumbs}
      navigate={(to: Breadcrumb) => navigate(to.url)}
    />
  );
};

export default Breadcrumbs;
