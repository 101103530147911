export const substituteRouteParams = (
  route: string,
  params: Record<string, string>
) => {
  let replacedRoute = route;

  Object.entries(params).forEach(([key, value]) => {
    replacedRoute = replacedRoute.replace(`:${key}`, value);
  });

  return replacedRoute;
};
